* {
  margin: 0;
  padding: 0;
}

*, *:after, *:before {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "TO Record RD";
  src: url(/static/media/TORecordRD-Medium.dac7c0f3.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
}

html, body {
  font-family: "TO Record RD", monospace, sans-serif;
  /* line-height: 1; */
  /* width: 100%;
  height: 100%; */
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  -webkit-font-feature-settings: "ss01";
          font-feature-settings: "ss01";
}

a {
  color: black;
  text-decoration: none;
}
li {
  text-decoration: none;
}

.ss01 { -webkit-font-feature-settings: "ss01"; font-feature-settings: "ss01"; }
.ss15 { -webkit-font-feature-settings: "ss15"; font-feature-settings: "ss15"; }
.ss16 { -webkit-font-feature-settings: "ss16"; font-feature-settings: "ss16"; }
.ss17 { -webkit-font-feature-settings: "ss17"; font-feature-settings: "ss17"; }
.ss18 { -webkit-font-feature-settings: "ss18"; font-feature-settings: "ss18"; }
.ss19 { -webkit-font-feature-settings: "ss19"; font-feature-settings: "ss19"; }

.bg {
  font-size: 8.3em;
  line-height: .73;
}
.md {
  font-size: 1.2em;
  line-height: .73;
}
.sm {
  font-size: 0.6em;
  line-height: 1.2em;
}

.editor h1 {
  font-weight: normal;
  font-size: 1.24em;
  line-height: .8;
  margin: 0 0 .8em 0;
}
.editor p {
  font-size: 0.6em;
  line-height: 1.2em;
  margin-bottom: 1.65333em;
}
.editor span {
  font-size: 0.6em;
  line-height: 1.2em;
}

.p-h { padding: .5em; }
.pt-h { padding-top: .5em; }
.pr-h { padding-right: .5em; }
.pb-h { padding-bottom: .5em; }
.pl-h { padding-left: .5em; }
.mt-h { margin-top: .5em; }
.mr-h { margin-right: .5em; }
.mb-h { margin-bottom: .5em; }
.ml-h { margin-left: .5em; }
.pb-1 { padding-bottom: 1em; }

.mt-s { margin-top: .1em; }
.mb-s { margin-bottom: .3em; }

.tabular { -webkit-font-feature-settings: "tnum"; font-feature-settings: "tnum"; font-variant-numeric: tabular-nums; }
.t-left { text-align: left; }
.t-right { text-align: right; }
.t-nowrap { white-space: nowrap; }
.t-clip {
  text-overflow: clip;
  overflow: hidden;
}
.t-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
.t-white, .t-white * { color: white; }
.t-black, .t-black * { color: black; }

.bold { font-weight: bold; }

.f-left { float: left; }
.f-right { float: right; }

.sticky { position: -webkit-sticky; position: sticky; }
.absolute { position: absolute; }
.relative { position: relative; }
.width { width: 100%; }
.width-0 { width: 0; }
.height { height: 100%; }
.left { left: 0; }
.right { right: 0; }
.top { top: 0; }
.bottom { bottom: 0; }

.fullscreen {
  width: 100vw;
  height: 100vh;
}

.z-back { z-index: -100; }
.z-front { z-index: 100; }

.flex { display: -ms-flexbox; display: flex; }
.flex-row { -ms-flex-direction: row; flex-direction: row; }
.flex-column { -ms-flex-direction: column; flex-direction: column; }
.flex-align-x-left { -ms-flex-pack: start; justify-content: flex-start; }
.flex-align-x-center { -ms-flex-pack: center; justify-content: center; }
.flex-align-y-top { -ms-flex-align: start; align-items: flex-start; }
.flex-align-y-center { -ms-flex-align: center; align-items: center; }
.flex-align-y-bottom { -ms-flex-align: end; align-items: flex-end; }
.flex-wrap { -ms-flex-wrap: wrap; flex-wrap: wrap; }
.flex-0 { -ms-flex-preferred-size: 0%; flex-basis: 0%; }
.flex-15 { -ms-flex-preferred-size: 15%; flex-basis: 15%; }
.flex-20 { -ms-flex-preferred-size: 20%; flex-basis: 20%; }
.flex-25 { -ms-flex-preferred-size: 25%; flex-basis: 25%; }
.flex-30 { -ms-flex-preferred-size: 30%; flex-basis: 30%; }
.flex-35 { -ms-flex-preferred-size: 35%; flex-basis: 35%; }
.flex-40 { -ms-flex-preferred-size: 40%; flex-basis: 40%; }
.flex-45 { -ms-flex-preferred-size: 45%; flex-basis: 45%; }
.flex-50 { -ms-flex-preferred-size: 50%; flex-basis: 50%; }
.flex-55 { -ms-flex-preferred-size: 55%; flex-basis: 55%; }
.flex-60 { -ms-flex-preferred-size: 60%; flex-basis: 60%; }
.flex-65 { -ms-flex-preferred-size: 65%; flex-basis: 65%; }
.flex-70 { -ms-flex-preferred-size: 70%; flex-basis: 70%; }
.flex-75 { -ms-flex-preferred-size: 75%; flex-basis: 75%; }
.flex-80 { -ms-flex-preferred-size: 80%; flex-basis: 80%; }
.flex-85 { -ms-flex-preferred-size: 85%; flex-basis: 85%; }
.flex-90 { -ms-flex-preferred-size: 90%; flex-basis: 90%; }
.flex-95 { -ms-flex-preferred-size: 95%; flex-basis: 95%; }
.flex-100 { -ms-flex-preferred-size: 100%; flex-basis: 100%; }
.flex-noshrink { -ms-flex-negative: 0; flex-shrink: 0; }
.flex-nogrow { -ms-flex-positive: 0; flex-grow: 0; }
@media screen and (max-width: 60rem) {
  .flex-m { -ms-flex-preferred-size: 100%; flex-basis: 100%; }
}

.block { display: block; }
.inline-block { display: inline-block; }

.pointer { cursor: pointer; }
.pointer-l { cursor: w-resize; }
.pointer-r { cursor: e-resize; }
.noselect { -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.hidden { overflow: hidden; }
.hidden-x { overflow-x: hidden; }
.scroll {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.noscrollbar-x {
  box-sizing: content-box;
  padding-right: 2em;
}
.noscrollbar-y {
  box-sizing: content-box;
  padding-bottom: 5em;
}
::-webkit-scrollbar {
  display: none;
}

/* https://stackoverflow.com/a/17822836/7662622 */
.repaint { box-shadow: 0 0 1px rgba(0, 0, 0, 0.01); }

.white { background-color: white; }
.black { background-color: black; }
.shadow { box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.transition-shadow { -webkit-transition: box-shadow 0.3s ease; transition: box-shadow 0.3s ease; }
.transition-flex { -webkit-transition: flex .8s ease; transition: flex .8s ease; transition: flex .8s ease, -ms-flex .8s ease; }
.transition-transform { -webkit-transition: -webkit-transform .8s ease; transition: -webkit-transform .8s ease; transition: transform .8s ease; transition: transform .8s ease, -webkit-transform .8s ease; }

.desktop { display: block; }
.mobile { display: none; }
@media screen and (max-width: 60rem) {
  .desktop { display: none; }
  .mobile { display: block; }
}

.b-top { border-top: solid black .1em; }
.b-bottom { border-bottom: solid black .1em; }

.back-center { background-position: center center; }
.back-lefttop { background-position: left top; }
.back-norepeat { background-repeat: no-repeat; }
.back-contain { background-size: contain; }

@-webkit-keyframes fadein {
  0%   { opacity: 0.25; }
  50%  { opacity: 0.5; }
  100% { opacity: 1; }
}

@keyframes fadein {
  0%   { opacity: 0.25; }
  50%  { opacity: 0.5; }
  100% { opacity: 1; }
}

